<template>
  <v-hover #default="{ hover }">
    <v-btn
      class="px-11 action-outlined-btn"
      v-bind="$attrs"
      v-on="$listeners"
      :class="hover ? 'actionButtonHoverBg actionButtonHoverColor--text' : 'cardBorder--text'"
      outlined
      :height="height"
      :ripple="false"
    >
      <span
        class="font-normal"
        :class="hover ? '' : `${getTextColor}`"
      >
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'ActionOutlinedButton',
  inheritAttrs: false,
  props: {
    height: {
      type: Number | String,
      default: 48
    },
    textColor: {
      type: String,
      default: 'nameField--text'
    }
  },
  computed: {
    getTextColor() {
      return this.textColor
    }
  }
}
</script>
