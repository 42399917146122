<template>
  <div
    class="custom-switcher-sm-wrapper"
    :class="[bgColor, { 'disabled': disabled }, { 'loading': loading }]"
  >
    <input
      v-bind="$attrs"
      type="checkbox"
      :checked="value"
      :id="_uid"
      @click="changeSwitcher"
      class="custom-switcher-sm-checkbox"
      :class="{
        'on': value,
        'off': !value
      }"
    >
    <label :for="_uid" class="custom-switcher-sm"></label>
  </div>
</template>
<script>
export default {
  name: 'SwitchSm',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      required: true
    },
    bgColor: {
      type: String,
      default: 'switcherYellow'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    changeSwitcher(e) {
      if (!this.disabled && !this.loading) {
        this.$emit('input', e.target.checked)
      }
    }
  }
}
</script>
