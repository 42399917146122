<template>
  <v-hover #default="{ hover }">
    <v-text-field
      class="search-input-field font-normal"
      :class="{ 'active-search': focusField || hover }"
      v-model="searchValue"
      v-bind="$attrs"
      v-on="$listeners"
      label="Search"
      :height="height"
      flat
      solo
      :background-color="focusField || hover ? 'inputFocus' : ''"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <template #prepend-inner>
        <v-icon @click="clearSearch" class="search-icon">
          {{ inputIcon }}
        </v-icon>
      </template>
    </v-text-field>
  </v-hover>
</template>

<script>
export default {
  name: 'SearchField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: String | Number,
      default: 46
    }
  },
  data: () => ({
    focusField: false,
    serverSearchInterval: null,
    emptySearch: true,
    searchValue: '',
  }),
  computed: {
    inputIcon() {
      return !this.searchValue ? '$searchIcon' : '$closeIcon'
    }
  },
  methods: {
    changeFocus(val) {
      this.focusField = val
    },
    clearSearch() {
      this.searchValue = ''
    },
    serverSearch(search) {
      if (!this.emptySearch || search && search.length) {
        this.emptySearch = !search
        clearInterval(this.serverSearchInterval)
        this.serverSearchInterval = setInterval(() => {
          clearInterval(this.serverSearchInterval)
          this.$emit('changedSearch')
        }, 500)
      }
    },
  },
  watch: {
    searchValue: {
      handler(value, oldValue) {
        if (!value || value.trim() !== oldValue.trim()) {
          this.$emit('input', value.trim() ? value.trim() : null)
          this.serverSearch(value)
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.serverSearchInterval)
  },
}
</script>

<style lang="scss">
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
}

.search-input-field{
  max-width: 260px;
  
  &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding: 0 14px;
  }

  &.v-text-field--outlined .v-label {
    top: inherit;
  }

  .v-label {
    color: #6C6C6C;
  }

  fieldset{
    border-color: var(--v-inputBorder-base);
  }
}
</style>
